import {
  autoUpdate,
  offset,
  size,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslations } from "next-intl";
import {
  CSSProperties,
  DetailedHTMLProps,
  Dispatch,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  SetStateAction,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";
import { FieldValues, UseFormClearErrors, UseFormSetValue } from "react-hook-form";

import Icon from "~/components/common/icon";
import { useBreakpoint } from "~/contexts/breakpoint";

import Button from "../common/button";
import EscapeNewLine from "../common/escape-new-line";
import Checkbox from "./checkbox";
import styles from "./input-field.module.scss";

type CustomStyles = {
  tooltipContainer?: string;
  tooltipButton?: string;
  icon?: string;
  tooltipPanel?: string;
  buttonIcon?: string;
  submitField?: string;
  container?: string;
  containerWithErrors?: string;
  containerInFocus?: string;
  hidden?: string;
  wrapperLabelField?: string;
  labelField?: string;
  mandatory?: string;
  wrapperInputField?: string;
  wrapperInputFieldUneditable?: string;
  inputField?: string;
  emptyValueCheckbox?: string;
  errorMessage?: string;
  successMessage?: string;
  informativeMessage?: string;
};

type InputFieldTooltipProps = {
  tooltip: string;
  customStyles?: CustomStyles;
};

export function InputFieldTooltip({ tooltip, customStyles }: InputFieldTooltipProps) {
  const breakpoint = useBreakpoint();
  const [open, setOpen] = useState<boolean>(false);
  const idTooltip = useId();

  const { x, y, refs, strategy, context } = useFloating({
    placement: breakpoint === "desktop" ? "bottom-start" : "bottom-end",
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(8),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${rects.reference.width - 16}px`,
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  // Event listeners to change the open state
  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  // Role props for screen readers
  const role = useRole(context, { role: "tooltip" });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

  return (
    <div
      ref={refs.setPositionReference}
      className={classNames(styles.tooltipContainer, customStyles?.tooltipContainer)}
    >
      <button
        type="button"
        ref={refs.setReference}
        {...getReferenceProps()}
        className={classNames(styles.tooltipButton, customStyles?.tooltipButton)}
        aria-disabled={breakpoint === "desktop" ? true : false}
        aria-labelledby={idTooltip}
      >
        <Icon name="info" width={16} height={16} className={classNames(styles.icon, customStyles?.icon)} />
      </button>
      {open && (
        <AnimatePresence>
          <motion.div
            ref={refs.setFloating}
            {...getFloatingProps()}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            className={classNames(styles.tooltipPanel, customStyles?.tooltipPanel)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div id={idTooltip}>
              <EscapeNewLine text={tooltip} />
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
}

type InputFieldButtonProps = {
  name?: string;
  error?: string;
  hideErrorIcon?: boolean;
  buttonName?: string | ReactNode;
  disabled?: boolean;
  isInputFieldCorrect?: boolean;
  setInputFieldCorrect?: Dispatch<SetStateAction<boolean>>;
  onClearErrors?: () => void;
  isSubmitting?: boolean;
  customStyles?: CustomStyles;
  clearValue?: () => void;
};

export function InputFieldButton({
  name,
  error,
  hideErrorIcon,
  buttonName,
  disabled,
  isInputFieldCorrect,
  isSubmitting,
  customStyles,
  clearValue,
}: InputFieldButtonProps) {
  const t = useTranslations();

  if (disabled) {
    return <Icon name="uneditable" width={16} height={16} className={classNames(styles.icon, customStyles?.icon)} />;
  }

  if (error) {
    if (clearValue) {
      return (
        <button
          type="button"
          className={styles.buttonIcon}
          onClick={(e) => {
            e.preventDefault();
            if (name) {
              clearValue();
            }
          }}
          aria-label={t("a11y.clear_field")}
        >
          <Icon name="close" width={16} height={16} className={styles.icon} />
        </button>
      );
    } else if (buttonName) {
      if (typeof buttonName === "string") {
        return (
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            className={classNames(styles.submitField, customStyles?.submitField)}
            type="submit"
            value="submit"
          >
            {buttonName}
          </Button>
        );
      } else {
        return <>{buttonName}</>;
      }
    } else if (!hideErrorIcon) {
      return <Icon name="alert-red" className={classNames(styles.icon, customStyles?.icon)} />;
    } else {
      return null;
    }
  }

  if (buttonName) {
    if (typeof buttonName === "string") {
      return (
        <Button
          disabled={isSubmitting}
          loading={isSubmitting}
          className={classNames(styles.submitField, customStyles?.submitField)}
          type="submit"
          value="submit"
        >
          {buttonName}
        </Button>
      );
    } else {
      return <>{buttonName}</>;
    }
  } else if (isInputFieldCorrect) {
    return <Icon name="checkmark" width={16} height={16} className={classNames(styles.icon, customStyles?.icon)} />;
  }

  return null;
}

export type OptionalCheckboxProps = {
  canBeConditionallyEmpty?: boolean;
  valueWhenEmpty?: string;
  defaultEmpty?: boolean;
  emptyCheckboxLabel?: string;
};

type InputFieldProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  label: string;
  isOptional?: boolean;
  buttonName?: string | ReactNode;
  error?: string;
  hideErrorIcon?: boolean;
  hideLabel?: boolean;
  informativeMessage?: string;
  successMessage?: string;
  tooltip?: string;
  disableButton?: boolean;
  optionalCheckbox?: OptionalCheckboxProps; // Optional empty checkbox properties
  //react hook form props
  onClearErrors?: () => void;
  isSubmitting?: boolean;
  customStyles?: CustomStyles;
  clearValue?: () => void;
  clearErrors?: UseFormClearErrors<FieldValues>;
  setValue?: UseFormSetValue<FieldValues>;
  externalError?: string; // New prop to receive external error state
};

export default forwardRef<HTMLInputElement, InputFieldProps>(function InputField(
  {
    label,
    isOptional = false,
    buttonName,
    error,
    hideErrorIcon = false,
    hideLabel = false,
    informativeMessage,
    successMessage,
    tooltip,
    disableButton,
    clearValue,
    setValue,
    externalError, // Receive external error state
    optionalCheckbox,
    //react hook form props
    name,
    clearErrors,
    onBlur,
    onClearErrors,
    isSubmitting,
    placeholder,
    className,
    defaultValue,
    customStyles,
    ...rest
  },
  ref
) {
  const calculatedId = useId();
  const id = rest.id ?? calculatedId;
  const t = useTranslations();
  // TODO refactor with actual working logic does not make any sense at the moment and it's misleading for the user
  const [isInputFieldCorrect, setInputFieldCorrect] = useState(false);
  const [isInputFocus, setInputFocus] = useState(false);

  // Deconstruct optional empty checkbox properties
  const {
    canBeConditionallyEmpty = false,
    valueWhenEmpty,
    emptyCheckboxLabel,
    defaultEmpty = false,
  } = optionalCheckbox || {};

  const refLabel = useRef<HTMLLabelElement>(null);
  const [readOnlyState, setReadOnlyState] = useState<boolean>(
    rest.readOnly || (canBeConditionallyEmpty && defaultEmpty)
  );
  const [widthLabel, setWidthLabel] = useState<number | undefined>();

  const styleLabelLine = {
    "--label-line-width": `calc(100% - ${widthLabel}px - ${
      tooltip ? 20 : 0
    }px - var(--label-external-padding-horizontal))`,
  } as CSSProperties;

  useEffect(() => {
    setWidthLabel(refLabel.current?.offsetWidth);
  }, [isOptional]);

  // Determine the error state to display based on the priority of externalError over internal error
  const displayError = externalError || error;

  return (
    <div
      className={classNames(
        className,
        styles.container,
        displayError ? styles.containerWithErrors : null,
        isInputFocus ? styles.containerInFocus : null,
        rest.disabled ? styles.containerDisabled : null,
        rest.hidden ? styles.hidden : null
      )}
    >
      <div
        className={classNames(
          styles.wrapperLabelField,
          hideLabel ? styles.visuallyHidden : null,
          customStyles?.wrapperLabelField,
          readOnlyState || rest.readOnly ? styles.readOnly : null
        )}
      >
        <label
          htmlFor={id}
          ref={refLabel}
          style={styleLabelLine}
          className={classNames(styles.labelField, customStyles?.labelField)}
          onMouseDown={(e) => e.preventDefault()}
        >
          {label}
          {isOptional ? (
            ""
          ) : (
            <span className={classNames(styles.mandatory, customStyles?.mandatory)} aria-hidden>
              {t("generic.form.mandatory_short")}
            </span>
          )}
        </label>
        {tooltip ? <InputFieldTooltip tooltip={tooltip} /> : null}
      </div>
      <div
        className={classNames(
          styles.wrapperInputField,
          customStyles?.wrapperInputField,
          rest.disabled ? styles.wrapperInputFieldUneditable : null,
          readOnlyState || rest.readOnly ? styles.readOnly : null
        )}
      >
        <input
          {...rest}
          className={classNames(styles.inputField, customStyles?.inputField)}
          placeholder={placeholder}
          id={id}
          ref={ref}
          name={name}
          onBlur={(e) => {
            // if (!displayError && e.currentTarget.value != undefined && e.currentTarget.value != "")
            // setInputFieldCorrect(true);
            if (onBlur) onBlur(e);
            setInputFocus(false);
          }}
          onFocus={() => {
            // setInputFieldCorrect(false);
            // onClearErrors?.();
            setInputFocus(true);
          }}
          readOnly={readOnlyState || rest.readOnly}
          disabled={rest.disabled}
          defaultValue={readOnlyState ? valueWhenEmpty : defaultValue}
          aria-invalid={displayError ? "true" : "false"}
          aria-errormessage={`${id}-error`}
          aria-readonly={readOnlyState || rest.readOnly}
          aria-disabled={rest.disabled}
        />
        {!disableButton && (
          <InputFieldButton
            name={name}
            error={displayError}
            hideErrorIcon={hideErrorIcon}
            buttonName={buttonName}
            disabled={rest.disabled}
            isInputFieldCorrect={isInputFieldCorrect}
            setInputFieldCorrect={setInputFieldCorrect}
            onClearErrors={onClearErrors}
            isSubmitting={isSubmitting}
            clearValue={clearValue}
          />
        )}
      </div>
      {/* {conditionalValueConfig?.controllerComponent ? ( */}
      {canBeConditionallyEmpty ? (
        <div className={classNames(styles.emptyValueCheckbox, customStyles?.emptyValueCheckbox)}>
          <Checkbox
            id={`empty-${id}`}
            name={`empty-${name}`}
            label={emptyCheckboxLabel}
            defaultChecked={readOnlyState}
            checked={readOnlyState}
            aria-checked={readOnlyState}
            required={false}
            disabled={rest.disabled}
            aria-disabled={rest.disabled}
            onChange={(evt): void => {
              if (name) {
                if (evt?.target?.checked) {
                  clearErrors?.(name);
                  setReadOnlyState(true);
                  setValue?.(name, valueWhenEmpty);
                } else {
                  setReadOnlyState(false);
                  setValue?.(name, "");
                }
              }
            }}
          />
        </div>
      ) : null}
      {error ? (
        <p id={`${id}-error`} className={classNames(styles.errorMessage, customStyles?.errorMessage)}>
          {error}
        </p>
      ) : successMessage ? (
        <p className={classNames(styles.successMessage, customStyles?.successMessage)}>{successMessage}</p>
      ) : informativeMessage ? (
        <p className={classNames(styles.informativeMessage, customStyles?.informativeMessage)}>{informativeMessage}</p>
      ) : (
        <></>
      )}
    </div>
  );
});
